<template>
    <div>
        <vx-card class="mr-4 p-0">
            <div class="vx-row mb-2">
                <div class="vx-col w-1/2">
                    <label class="vs-input--label ml-1">Marca</label>
                    <v-select                                               
                        :options="CONSTANTE_MARCAS_MODULO" 
                        v-model="parametroEnvio.marcaModulo" 
                        :reduce="r => r.data"                     
                        class="w-full vs-input--label">
                    </v-select> 
                </div>
                    <div class="vx-col w-1/2">
                    <vs-input label="Serial" v-model="parametroEnvio.serialModulo" class="w-full ml-1" />        
                </div>                
            </div>
            <div class="vx-row">
                <div class="vx-col w-1/2">
                    <div class="flex items-center">                                                                        
                        <label class="vs-input--label mr-auto">Comando</label>  
                        <vs-button icon="search" color="primary" @click="fetchListaComandos" :disabled="!parametroEnvio.serialModulo"> Listar Comando Enviados</vs-button>
                    </div>
                </div>
                <div class="vx-col w-1/2">
                    <label class="vs-input--label ml-2 mr-2" v-if="exibirParametro">Parâmetro</label>
                    <br/>  
                    <label class="vs-input--label ml-2 mr-2" v-if="exibirParametro">
                        <feather-icon icon="InfoIcon" svgClasses="w-4 h-4 stroke-current text-primary"/>
                        {{labelValorParametro}}
                    </label>  
                </div>
            </div>
            <div class="vx-row mt-2">
                <div class="vx-col w-1/2">                
                    <v-select                                               
                        :options="getComandosModuloPorMarca" 
                        v-model="comando" 
                        @input="verificarComandoSelecionado"                 
                        class="w-full text-center">
                    </v-select>       
                </div>
                <div class="vx-col w-1/2">                     
                    <div class="flex items-center">             
                        <vs-input class="w-full ml-2" v-model="valorParametro" :maxlength="400" v-if="exibirParametro" :readonly="labelValorParametro1"/> 
                        <vs-button class="ml-2" icon="send" color="primary" :disabled="(comando && exibirParametro && !valorParametro) || !comando || isSuporteInterno" @click="enviarComando"/>  
                    </div>
                    <label class="vs-input--label mr-2" v-if="exibirParametro">{{textoExplicativoParametro}}</label>  
                    <div v-if="labelValorParametro1" class="mb-2" >     
                        <div v-if="labelValorParametro1">
                            <label class="vs-input--label ml-2 mr-2">{{labelValorParametro1}}</label>        
                            <vs-input class="w-full ml-2" v-model="valorParametro1" :maxlength="400" v-on:keyup="concatenarValorParametro"/> 
                        </div>
                        <div v-if="labelValorParametro2">
                            <label class="vs-input--label ml-2 mr-2">{{labelValorParametro2}}</label>        
                            <vs-input class="w-full ml-2" v-model="valorParametro2" :maxlength="400" v-on:keyup="concatenarValorParametro"/> 
                        </div>
                        <div v-if="labelValorParametro3">
                            <label class="vs-input--label ml-2 mr-2">{{labelValorParametro3}}</label>        
                            <vs-input class="w-full ml-2" v-model="valorParametro3" :maxlength="400" v-on:keyup="concatenarValorParametro"/> 
                        </div>
                        <div v-if="labelValorParametro4">
                            <label class="vs-input--label ml-2 mr-2">{{labelValorParametro4}}</label>        
                            <vs-input class="w-full ml-2" v-model="valorParametro4" :maxlength="400" v-on:keyup="concatenarValorParametro"/> 
                        </div>
                        <div v-if="labelValorParametro5">
                            <label class="vs-input--label ml-2 mr-2">{{labelValorParametro5}}</label>        
                            <vs-input class="w-full ml-2" v-model="valorParametro5" :maxlength="400" v-on:keyup="concatenarValorParametro"/> 
                        </div>
                        <div v-if="labelValorParametro6">
                            <label class="vs-input--label ml-2 mr-2">{{labelValorParametro6}}</label>        
                            <vs-input class="w-full ml-2" v-model="valorParametro6" :maxlength="400" v-on:keyup="concatenarValorParametro"/> 
                        </div>
                        <div v-if="labelValorParametro7">
                            <label class="vs-input--label ml-2 mr-2">{{labelValorParametro7}}</label>        
                            <vs-input class="w-full ml-2" v-model="valorParametro7" :maxlength="400" v-on:keyup="concatenarValorParametro"/> 
                        </div>
                    </div>   
                </div>                
            </div>
        </vx-card>
        <vs-table :data="listaComandosEnviados" class="tabelaComScroll mt-2" noDataText="Nenhuma comando enviado" stripe hoverFlat >
                <template slot="thead">
                    <vs-th ></vs-th>
                    <vs-th >Serial</vs-th>
                    <vs-th >Comando</vs-th>            
                    <vs-th >Parâmetro</vs-th>            
                    <vs-th >Status</vs-th>            
                    <vs-th >Resposta</vs-th>            
                    <vs-th >Cadastro / Envio / Execução</vs-th>                
                    <vs-th >Usuário</vs-th>
                    <vs-th >N.</vs-th>
                </template>
                <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td ><vs-button size="small" class="ml-2" icon="delete" color="danger" @click="cancelarComando(tr)" /></vs-td>                
                        <vs-td ><small style='font-family: Verdana, sans-serif;'>{{tr.serialModulo}}</small></vs-td>                
                        <vs-td ><small style='font-family: Verdana, sans-serif;'>{{tr.comandoEnviado}}</small></vs-td>                
                        <vs-td style="max-width: 150px"><vs-textarea style='word-wrap: break-word; font-family: Verdana, sans-serif; font-size: 10px' v-model="tr.valorParametro"/></vs-td>                
                        <vs-td ><small style='font-family: Verdana, sans-serif;'>{{tr.status}}</small></vs-td>                
                        <vs-td style="max-width: 350px"><vs-textarea style='word-wrap: break-word; font-family: Verdana, sans-serif; font-size: 10px' v-model="tr.respostaComando"/>  </vs-td>                
                        <vs-td ><small style='font-family: Verdana, sans-serif;'>{{tr.dataHoraCadastro}}<br/>{{tr.dataHoraEnvio}}<br/>{{tr.dataHoraResposta}}</small></vs-td>                                       
                        <vs-td ><small style='font-family: Verdana, sans-serif;'>{{tr.usuarioSolicitante}}</small></vs-td>                
                        <vs-td ><small style='font-family: Verdana, sans-serif;'>{{tr.qtdTentativas}}</small></vs-td>           
                    
                    </vs-tr>
                </template>
        </vs-table>  
        <vs-popup title="Resposta" :active.sync="popUpTodaResposta.exibir" v-if="popUpTodaResposta.exibir">
                {{popUpTodaResposta.texto}}
        </vs-popup>
    </div> 
</template>

<script>
import axios from "@/axios.js"

import { CONSTANTE_TIPOS_COMANDOS_TCP } from '@/constantesComboBox.js' 
import { CONSTANTE_MARCAS_MODULO } from '@/constantesComboBox.js'

export default {
    created() {
        this.CONSTANTE_TIPOS_COMANDOS_TCP = CONSTANTE_TIPOS_COMANDOS_TCP;
        this.CONSTANTE_MARCAS_MODULO = CONSTANTE_MARCAS_MODULO;
    },    
    computed: {
        isSuporteInterno(){
            return (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO');
        },
        getComandosModuloPorMarca() {
            if (this.parametroEnvio.marcaModulo) {
                let comandos =  this.CONSTANTE_TIPOS_COMANDOS_TCP.filter( ({modulos}) => modulos.toUpperCase().indexOf(this.parametroEnvio.marcaModulo.toUpperCase()) >= 0);
                
                if (comandos) {
                    return comandos;
                }
            }

            return [];
        }
    },
    data() {
        return {
            popUpTodaResposta: {
                exibir: false,
                texto: null
            },
            parametroEnvio: {marcaModulo: null, codigoModulo: null},
            listaComandosEnviados: [],
            comando: null,
            valorParametro: null,
            exibirParametro: false,
            labelValorParametro: null,
            textoExplicativoParametro: null,
            labelValorParametro1: null,
            valorParametro1: null,
            labelValorParametro2: null,
            valorParametro2: null,
            labelValorParametro3: null,
            valorParametro3: null,
            labelValorParametro4: null,
            valorParametro4: null,
            labelValorParametro5: null,
            valorParametro5: null,
            labelValorParametro6: null,
            valorParametro6: null,
            labelValorParametro7: null,
            valorParametro7: null,
        }
    },
    methods: {
        abrirPopUpTodaResposta(texto) {
            this.popUpTodaResposta.exibir = true;
            this.popUpTodaResposta.texto = texto;
        },
        fetchListaComandos() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('serialModulo', this.parametroEnvio.serialModulo);

            axios.post("/ListarComandosEnviadosModuloTCP", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                   this.listaComandosEnviados = response.data.lista;
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
        },
        cancelarComando(tr) {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('idComando', tr.idComando);
            if (this.pesquisarModuloBackup) {
                params.append('codigoModulo', tr.codigoModuloBkp);
                params.append('serialModulo', tr.serialModuloBkp);
            } else {
                params.append('codigoModulo', tr.codigoModulo);
                params.append('serialModulo', tr.serialModulo);
            }

            axios.post("/CancelarComandoTCPModulo", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    if (response.data.statusCodigo === 0) {
                        this.fetchListaComandos();
                    } else {
                        this.$vs.notify({
                            title: "Mensagem", text: response.data.mensagem,
                            iconPack: 'feather', icon: 'icon-alert-circle', color: 'warning' });
                    }                   
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
        },
        enviarComando() {  
            if (!this.isValorParametroValido())  {
                this.$vs.notify({
                            title: "Mensagem", text: 'Parâmetro inválido',
                            iconPack: 'feather', icon: 'icon-alert-circle', color: 'warning' });    
                return;
            }

            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('comando', this.comando.data);
            params.append('serialModulo', this.parametroEnvio.serialModulo);
            
            if (this.valorParametro) {
                params.append('valorParametro', this.valorParametro);
            }

            axios.post("/EnviarComandoTCPModulo", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    if (response.data.statusCodigo === 0) {
                        this.fetchListaComandos();
                    } else {
                        this.$vs.notify({
                            title: "Mensagem", text: response.data.mensagem,
                            iconPack: 'feather', icon: 'icon-alert-circle', color: 'warning' });
                    }                   
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        },
        verificarComandoSelecionado() {
            if (!this.comando) {
                this.exibirParametro = false;
                this.labelValorParametro1 = null;
                this.labelValorParametro2 = null;
                this.labelValorParametro3 = null;
                this.labelValorParametro4 = null;
                this.labelValorParametro5 = null;
                this.labelValorParametro6 = null;
                this.labelValorParametro7 = null;
                
                this.valorParametro1 = null;
                this.valorParametro2 = null;
                this.valorParametro3 = null;
                this.valorParametro4 = null;
                this.valorParametro5 = null;
                this.valorParametro6 = null;
                this.valorParametro7 = null;
                return;
            }

            let comandoSelecionado = this.comando.data;

            if (comandoSelecionado && (comandoSelecionado === 'SET_ODOMETER' || comandoSelecionado === 'SET_HOURMETER' 
                || comandoSelecionado === 'SPC_SET_HOURMETER' ||  comandoSelecionado === 'BIN_PARAM_TEMPO_PARA_FALHA_GPRS' || comandoSelecionado === 'SPC_SET_ODOMETER' 
                || comandoSelecionado === 'SETUP_ODOMETER_GPS_TRACE' || comandoSelecionado === 'APPLICATION_TIMING_PARAMETERS' || comandoSelecionado === 'SPC_GSR_DEB_STOPPED' 
                || comandoSelecionado === 'SPC_GSR_DEB_MOVING'  || comandoSelecionado === 'SETODOMETER' || comandoSelecionado === 'SETHMETER' || comandoSelecionado === 'SPC_SET_ODORPM_ENABLE')
                || comandoSelecionado === 'MILEAGE_CORRECTION' || comandoSelecionado === 'SET_HOURMETER_VALUE' 
                || comandoSelecionado === 'GT_SETODOMETER' || comandoSelecionado === 'GT_SETHOURMETER'
                || comandoSelecionado === 'GTIDA_ENABLE' || comandoSelecionado === 'SPC_KEEP_WORKING_BF_SLEEP' 
                || comandoSelecionado === 'SPC_ALLOW_ANY_TAG_ACC' || comandoSelecionado === 'SPC_ZIG_ACC_ENCRYPT_KEY' || comandoSelecionado === 'MILEAGE'
                || comandoSelecionado === 'BIN_PARAM_TEMPO_TRANS_IGN_SAT' || comandoSelecionado === 'BIN_PARAM_TEMPO_TRANS_PAN_SAT' 
                || comandoSelecionado === 'BIN_PARAM_TEMPO_TRANS_IGN_OFF' || comandoSelecionado === 'COMANDO_GENERICO' || comandoSelecionado === 'COMANDO_GENERICO_ST'
                || comandoSelecionado === 'COMANDO_GENERICO_TELTONIKA' || comandoSelecionado === 'TIMER' || comandoSelecionado === 'COMANDO_GENERICO_GLOBALPOSITION'
                || comandoSelecionado === 'E3_COMANDO_GENERICO' || comandoSelecionado === 'E3_TEMPO_COMUNICACAO' || comandoSelecionado === 'SETPARAM_ODOMETRO'
                || comandoSelecionado === 'SEND_MESSAGE_RS232'  || comandoSelecionado === 'COMANDO_GENERICO_GT') {
                
                this.exibirParametro = true;
                this.valorParametro = null;
                this.labelValorParametro1 = null;
                this.labelValorParametro2 = null;
                this.labelValorParametro3 = null;
                this.labelValorParametro4 = null;
                this.labelValorParametro5 = null;
                this.labelValorParametro6 = null;
                this.labelValorParametro7 = null;
                
                this.valorParametro1 = null;
                this.valorParametro2 = null;
                this.valorParametro3 = null;
                this.valorParametro4 = null;
                this.valorParametro5 = null;
                this.valorParametro6 = null;
                this.valorParametro7 = null;
                
                if (comandoSelecionado === 'SET_ODOMETER' || comandoSelecionado === 'SPC_SET_ODOMETER' || comandoSelecionado === 'SETUP_ODOMETER_GPS_TRACE' 
                    || comandoSelecionado === 'SETODOMETER' || comandoSelecionado === 'MILEAGE_CORRECTION' || comandoSelecionado === 'GT_SETODOMETER' 
                    || comandoSelecionado === 'MILEAGE' || comandoSelecionado === 'SETPARAM_ODOMETRO') {
                    this.labelValorParametro = "Odômetro em KM";
                } else if (comandoSelecionado === 'SET_HOURMETER' || comandoSelecionado === 'SPC_SET_HOURMETER' || comandoSelecionado === 'SETHMETER' || comandoSelecionado === 'SET_HOURMETER_VALUE'
                    || comandoSelecionado === 'GT_SETHOURMETER') { 
                    this.labelValorParametro = "Horímetro em Minutos";
                } else if ( comandoSelecionado === 'SPC_SET_ODORPM_ENABLE' ){
                    this.labelValorParametro = "0:desativar, 1 odometro, 2 RPM, 3 ambos";
                } else if ( comandoSelecionado === 'GTIDA_ENABLE' ){
                    this.labelValorParametro = "Informe os IDs dos cartões separados por vírgula";
                } else if ( comandoSelecionado === 'SPC_KEEP_WORKING_BF_SLEEP' ){
                    this.labelValorParametro = "Tempo em horas. 0 desativa o sleep.";
                    this.valorParametro = "1";
                } else if ( comandoSelecionado == 'SPC_ALLOW_ANY_TAG_ACC' ){
                    this.labelValorParametro = "0 - Desabilitar. 1 - para Habilitar";
                    this.valorParametro = "1";
                } else if ( comandoSelecionado === 'SPC_ZIG_ACC_ENCRYPT_KEY' ){
                    this.labelValorParametro = "Chave de criptografia com 32 caracteres.";
                    this.valorParametro = "2102030405060708090a0b0c0d0e0f01";
                } else if (comandoSelecionado === 'COMANDO_GENERICO' || comandoSelecionado === 'COMANDO_GENERICO_ST' || comandoSelecionado === 'COMANDO_GENERICO_TELTONIKA'
                || comandoSelecionado === 'COMANDO_GENERICO_GLOBALPOSITION' || comandoSelecionado === 'E3_COMANDO_GENERICO' || comandoSelecionado === 'SEND_MESSAGE_RS232'  || comandoSelecionado === 'COMANDO_GENERICO_GT') {
                    this.labelValorParametro = "Cole o comando no campo parâmetro.";
                } else {
                    this.labelValorParametro = "Informe o parâmetro";
                }
                
                if (comandoSelecionado === 'SPC_GSR_DEB_STOPPED') {
                    this.valorParametro = "120";
                }
                if (comandoSelecionado === 'SPC_GSR_DEB_MOVING') {
                    this.valorParametro = "5";
                }
                
                if (comandoSelecionado === 'BIN_PARAM_TEMPO_PARA_FALHA_GPRS' || comandoSelecionado === 'BIN_PARAM_TEMPO_TRANS_IGN_SAT' ||
                    comandoSelecionado === 'BIN_PARAM_TEMPO_TRANS_PAN_SAT' || comandoSelecionado === 'BIN_PARAM_TEMPO_TRANS_IGN_OFF') {
                    this.valorParametro = "600";
                }
            } else  if (comandoSelecionado && comandoSelecionado === 'REPORTING') {
                this.exibirParametro = true;
                this.labelValorParametro1 = "Intervalo de envio parado";
                this.labelValorParametro2 = "Intervalo de envio em movimento";
                this.labelValorParametro3 = "Intervalo de envio em emergência";
                this.labelValorParametro4 = "Numero de envio de emergência";
                this.labelValorParametro5 = "Distância para envio de posições";
                this.labelValorParametro6 = "Intervalo de envio de Keep Alive";
                this.labelValorParametro7 = null;	
                
                this.valorParametro1 = "300";
                this.valorParametro2 = "60";
                this.valorParametro3 = "60";
                this.valorParametro4 = "1";
                this.valorParametro5 = "500";
                this.valorParametro6 = "0";
                this.valorParametro7 = null;
                
                this.labelValorParametro = "Parametro total";
                this.concatenarValorParametro();
            } else if (comandoSelecionado && comandoSelecionado === 'NEW_PARAMETER_SETTING') {
                this.exibirParametro = true;
                this.labelValorParametro1 = "Ângulo para envio de posição";
                this.labelValorParametro2 = "FIFO / LIFO";
                this.labelValorParametro3 = "Intervalo para acionar botão anti-furto";
                this.labelValorParametro4 = "Sensibilidade para movimento";
                this.labelValorParametro5 = "Delay para detectar movimento";
                this.labelValorParametro6 = "Porcentagem modulo em movimento";
                this.labelValorParametro7 = "Habilitar horímetro";	                
                
                this.valorParametro1 = "10";
                this.valorParametro2 = "0";
                this.valorParametro3 = "30";
                this.valorParametro4 = "3";
                this.valorParametro5 = "3";
                this.valorParametro6 = "70";
                this.valorParametro7 = "1";                
                
                this.labelValorParametro = "Parâmetro total";
                this.concatenarValorParametro();
                
            } else if (comandoSelecionado && comandoSelecionado === 'PARAMETER_VARIOUS_FEATURES') {
                this.exibirParametro = true;
                this.labelValorParametro1 = "Velocidade Máxima";
                this.labelValorParametro2 = "Tempo Sleep";
                this.labelValorParametro3 = null;				
                this.labelValorParametro4 = null;				
                this.labelValorParametro5 = null;				
                this.labelValorParametro6 = null;				
                this.labelValorParametro7 = null;				
                
                this.valorParametro1 = "120";
                this.valorParametro2 = "2";
                this.valorParametro3 = null;
                this.valorParametro4 = null;
                this.valorParametro5 = null;
                this.valorParametro6 = null;
                this.valorParametro7 = null;
                
                this.labelValorParametro = "Parâmetro total";   
                this.concatenarValorParametro();             
            } else {
                this.exibirParametro = false;
                this.valorParametro = null;
            }
        },
        concatenarValorParametro() {
            let comandoSelecionado = this.comando.data;
            if (comandoSelecionado && comandoSelecionado === 'REPORTING') {
                this.valorParametro = this.valorParametro1 + ";" + this.valorParametro2 + ";" + 
                this.valorParametro3 + ";" + this.valorParametro4 + ";" +
                    this.valorParametro5 + ";" + this.valorParametro6 + ";0;0;" + this.valorParametro6;
            } else if (comandoSelecionado && comandoSelecionado === 'NEW_PARAMETER_SETTING') {
                this.valorParametro = this.valorParametro1 + ";" + this.valorParametro2 + ";" + this.valorParametro3 + ";0;0;0;0;" 
                + this.valorParametro4 + ";" + this.valorParametro5 + ";" +
                    this.valorParametro6 + ";5;10;70;" + this.valorParametro7;
            } else if (comandoSelecionado && comandoSelecionado === 'PARAMETER_VARIOUS_FEATURES') {
                this.valorParametro = "02;1;" + this.valorParametr1 +";" + this.valorParametro2 + ";0;0;0;1;0;1;7;0;1;0";		
            }
        },
        isValorParametroValido() {
            let comandoSelecionado = this.comando.data;
            if (comandoSelecionado === 'SET_ODOMETER' || comandoSelecionado === 'SPC_SET_ODOMETER' || comandoSelecionado === 'SETUP_ODOMETER_GPS_TRACE' 
                    || comandoSelecionado === 'SETODOMETER' || comandoSelecionado === 'MILEAGE_CORRECTION' || comandoSelecionado === 'GT_SETODOMETER' 
                    || comandoSelecionado === 'MILEAGE' || comandoSelecionado === 'SETPARAM_ODOMETRO'
                ) {
                
                if (!this.valorParametro) {
                    return false;
                } else if (isNaN(Number(this.valorParametro))) {                    
                    return false;
                }
            } else if (comandoSelecionado === 'SET_HOURMETER' || comandoSelecionado === 'SPC_SET_HOURMETER' || comandoSelecionado === 'SETHMETER' || comandoSelecionado === 'SET_HOURMETER_VALUE'
                    || comandoSelecionado === 'GT_SETHOURMETER' || comandoSelecionado === 'SPC_SET_ODORPM_ENABLE' || comandoSelecionado === 'SPC_KEEP_WORKING_BF_SLEEP' 
                    || comandoSelecionado === 'SPC_GSR_DEB_STOPPED' || comandoSelecionado === 'SPC_GSR_DEB_MOVING'  ) {
                 if (!this.valorParametro) {
                    return false;
                } else if (isNaN(parseInt(this.valorParametro))) {                    
                    return false;
                } else {
                    this.valorParametro = parseInt(this.valorParametro);
                }
            }
                
            return true;
        }
    }
}
</script>